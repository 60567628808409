body {
  margin: 0;
  /* font-family: 'Nunito'; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: linear-gradient(
    180deg,
    rgba(15, 66, 114, 1) 0%,
    rgba(120, 24, 118, 0.8505747126436781) 99%
  ); */

  /* background-color: hsl(180, 65.4%, 35.6%); */
  /* shore */
  /* background: #70e1f5;	
  background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);	
  background: linear-gradient(to right, #ffd194, #70e1f5); */

  /* background: linear-gradient(
      217deg,
      rgba(20, 22, 20, 0.8),
      rgba(255, 0, 0, 0) 90.71%
    ),
    linear-gradient(
      127deg,
      rgba(255, 209, 148, 0.8),
      rgba(0, 255, 0, 0) 70.71%
    ),
    linear-gradient(
      336deg,
      rgba(112, 225, 245, 0.8),
      rgba(0, 0, 255, 0) 70.71%
    ); */

  background: linear-gradient(
      217deg,
      rgb(144 229 255 / 80%),
      rgba(255, 0, 0, 0) 90.71%
    ),
    linear-gradient(
      127deg,
      rgb(255 195 213 / 80%),
      rgb(34 78 34 / 0%) 70.71%
    ),
    linear-gradient(
      336deg,
      rgba(241, 232, 105, 0.8),
      rgba(0, 0, 255, 0) 70.71%
    );

  background-repeat: no-repeat;
  background-attachment: fixed;

  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
