@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

* {
  font-family: 'Nunito';
}
.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.score-container {
  border: 2px solid yellow;
}

.black {
  margin-bottom: 1rem;
}

/* this is midi controls, unfortunately listed as empty div.
maybe we can attach a classname via the abc docs...? */
.row-controls > div:nth-of-type(2) {
  flex: 1 0 auto;
  width: 6rem;
  cursor: pointer;
}

.abcjs-inline-midi {
  height: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  background-color: #2fd566;
}

.abcjs-midi-reset,
.abcjs-midi-progress-background,
.abcjs-midi-clock {
  display: none !important;
}

span.row-controls div.abcjs-inline-midi button.abcjs-midi-start {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin-right: 0;
  float: none;
  cursor: pointer;
}

/* transition-group styles */
/* for cards */
.newcard-enter {
  opacity: 0;
  transform: translateY(-120%);
  /* transition: 0.5s; */
}
.newcard-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: all 200ms ease-out;
}
.newcard-exit {
  transform: translateY(0);
  opacity: 1;
}
.newcard-exit-active {
  opacity: 0;
  transform: translateY(120%);
  transition: all 200ms ease-out;
}

.card-enter {
  opacity: 0;
  transform: translateY(-120%);
  /* transition: 0.5s; */
}
.card-enter-active {
  opacity: 1;
  transform: translateY(0);
  /* transition: all 200ms ease-out; */
}
.card-exit {
  transform: translateY(0);
  opacity: 1;
}
.card-exit-active {
  opacity: 0;
  transform: translateY(120%);
  /* transition: all 200ms ease-out; */
}

/* for card rows */
.row-enter {
  opacity: 0;
  max-height: 0;
}
.row-enter-active {
  opacity: 1;
  max-height: 11rem;
  transition: all 200ms ease-in;
}
.row-exit {
  opacity: 1;
  max-height: 11rem;
}
.row-exit-active {
  opacity: 0;
  max-height: 0;
  transition: all 200ms ease-in;
}
