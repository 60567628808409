@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);
body {
  margin: 0;
  /* font-family: 'Nunito'; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: linear-gradient(
    180deg,
    rgba(15, 66, 114, 1) 0%,
    rgba(120, 24, 118, 0.8505747126436781) 99%
  ); */

  /* background-color: hsl(180, 65.4%, 35.6%); */
  /* shore */
  /* background: #70e1f5;	
  background: -webkit-linear-gradient(to right, #ffd194, #70e1f5);	
  background: linear-gradient(to right, #ffd194, #70e1f5); */

  /* background: linear-gradient(
      217deg,
      rgba(20, 22, 20, 0.8),
      rgba(255, 0, 0, 0) 90.71%
    ),
    linear-gradient(
      127deg,
      rgba(255, 209, 148, 0.8),
      rgba(0, 255, 0, 0) 70.71%
    ),
    linear-gradient(
      336deg,
      rgba(112, 225, 245, 0.8),
      rgba(0, 0, 255, 0) 70.71%
    ); */

  background: linear-gradient(
      217deg,
      rgb(144 229 255 / 80%),
      rgba(255, 0, 0, 0) 90.71%
    ),
    linear-gradient(
      127deg,
      rgb(255 195 213 / 80%),
      rgb(34 78 34 / 0%) 70.71%
    ),
    linear-gradient(
      336deg,
      rgba(241, 232, 105, 0.8),
      rgba(0, 0, 255, 0) 70.71%
    );

  background-repeat: no-repeat;
  background-attachment: fixed;

  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.key{float:left;position:relative;display:flex;align-items:flex-end;justify-content:center;cursor:pointer}.key span{font-size:.5rem;font-family:monospace;padding-bottom:4px}.white{height:4.59rem;width:1.9615384615rem;z-index:1;border-top:0 solid #aaa;border-bottom:0 solid #aaa;border-left:.03825rem solid #aaa;background-color:#fff}.black{height:2.5245rem;width:1.0788461538rem;z-index:2;border:.03825rem solid #222;border-top-width:0;background-color:#1c1c1c;border-bottom-right-radius:2px;border-bottom-left-radius:2px}.a,.b,.d,.e,.g,.black{margin:0 0 0 -.5776730769rem}.keyboard>.note:last-child{border-right:0 solid #aaa}.keyboard>.white:first-child{border-left:0 solid #aaa}.keyboard{box-shadow:0px 0px 0px 5px #fc5185;border-radius:10px;overflow:hidden}.keyboard .highlight{background-color:#f0f7b3}
* {
  font-family: 'Nunito';
}
.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.score-container {
  border: 2px solid yellow;
}

.black {
  margin-bottom: 1rem;
}

/* this is midi controls, unfortunately listed as empty div.
maybe we can attach a classname via the abc docs...? */
.row-controls > div:nth-of-type(2) {
  flex: 1 0 auto;
  width: 6rem;
  cursor: pointer;
}

.abcjs-inline-midi {
  height: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  background-color: #2fd566;
}

.abcjs-midi-reset,
.abcjs-midi-progress-background,
.abcjs-midi-clock {
  display: none !important;
}

span.row-controls div.abcjs-inline-midi button.abcjs-midi-start {
  width: 100%;
  height: 100%;
  display: inline-block;
  margin-right: 0;
  float: none;
  cursor: pointer;
}

/* transition-group styles */
/* for cards */
.newcard-enter {
  opacity: 0;
  -webkit-transform: translateY(-120%);
          transform: translateY(-120%);
  /* transition: 0.5s; */
}
.newcard-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  transition: all 200ms ease-out;
}
.newcard-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.newcard-exit-active {
  opacity: 0;
  -webkit-transform: translateY(120%);
          transform: translateY(120%);
  transition: all 200ms ease-out;
}

.card-enter {
  opacity: 0;
  -webkit-transform: translateY(-120%);
          transform: translateY(-120%);
  /* transition: 0.5s; */
}
.card-enter-active {
  opacity: 1;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  /* transition: all 200ms ease-out; */
}
.card-exit {
  -webkit-transform: translateY(0);
          transform: translateY(0);
  opacity: 1;
}
.card-exit-active {
  opacity: 0;
  -webkit-transform: translateY(120%);
          transform: translateY(120%);
  /* transition: all 200ms ease-out; */
}

/* for card rows */
.row-enter {
  opacity: 0;
  max-height: 0;
}
.row-enter-active {
  opacity: 1;
  max-height: 11rem;
  transition: all 200ms ease-in;
}
.row-exit {
  opacity: 1;
  max-height: 11rem;
}
.row-exit-active {
  opacity: 0;
  max-height: 0;
  transition: all 200ms ease-in;
}

