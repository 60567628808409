// code taken from Enteleforms answer:
// https://stackoverflow.com/questions/29656280/how-to-create-a-completely-flexible-piano-keyboard-with-html-and-css

// saved original styling since im tearing this thing apart
// $keyboard_Width: 25rem;
// $keyboard_Height: ($keyboard_Width * 0.18);
// $border_Width: ($keyboard_Width * 0.0015);
// $whiteKey_Count: 21;
// $whiteKey_Height: $keyboard_Height;
// $whiteKey_Width: ($keyboard_Width / $whiteKey_Count);
// $blackKey_Height: ($whiteKey_Height * 0.55);
// $blackKey_Width: ($whiteKey_Width * 0.55);
// $blackKey_Offset: (-($blackKey_Width / 2) - $border_Width);

// Responsive Sizing
// (currently optimized for horizontal scaling)
// $keyboard_Width: 40vw;
$keyboard_Width: 25.5rem;
$keyboard_Height: ($keyboard_Width * 0.18);
$border_Width: ($keyboard_Width * 0.0015);
$whiteKey_Count: 13;
$whiteKey_Height: $keyboard_Height;
$whiteKey_Width: ($keyboard_Width / $whiteKey_Count);
$blackKey_Height: ($whiteKey_Height * 0.55);
$blackKey_Width: ($whiteKey_Width * 0.55);
$blackKey_Offset: (-($blackKey_Width / 2) - $border_Width);

$whiteKey_Color: #fff; // #cbcbcb
$blackKey_Color: #1c1c1c; // #222222
$blackKey_BorderColor: #222222;
$whiteKey_BorderColor: #aaaaaa;

.key {
  float: left;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;

  span {
    font-size: 0.5rem;
    font-family: monospace;
    padding-bottom: 4px;
  }
}

.white {
  height: $whiteKey_Height;
  width: $whiteKey_Width;
  z-index: 1;
  border-top: 0 solid $whiteKey_BorderColor;
  border-bottom: 0 solid $whiteKey_BorderColor;
  border-left: $border_Width solid $whiteKey_BorderColor;
  background-color: $whiteKey_Color;
}

.black {
  height: $blackKey_Height;
  width: $blackKey_Width;
  z-index: 2;
  border: $border_Width solid $blackKey_BorderColor;
  border-top-width: 0;
  background-color: $blackKey_Color;

  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.a,
.b,
.d,
.e,
.g,
.black {
  margin: 0 0 0 $blackKey_Offset;
}

.keyboard > .note:last-child {
  border-right: 0 solid $whiteKey_BorderColor;
}
.keyboard > .white:first-child {
  border-left: 0 solid $whiteKey_BorderColor;
}

.keyboard {
  // width: 100%;
  box-shadow: 0px 0px 0px 5px #fc5185;
  border-radius: 10px;
  overflow: hidden;
  //transition: box-shadow 0.3s cubic-bezier(0.7, 0.23, 0.31, 1.2);
  // transition: box-shadow 0.1s ease-in;

  // &:hover {
  //   box-shadow: 0px 0px 0px 8px #fc5185;
  // }

  .highlight {
    background-color: #f0f7b3;
  }
}
